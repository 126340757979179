import { type AxiosInstance } from "axios";
import useAxiosAuth from "../hooks/useAxiosAuth.ts";
import { useQuery, useMutation } from "@tanstack/react-query";

const endpoints = {
  normal_analysis: "sites/full_report/",
  "20_mile_analysis": "sites/custom_miles_report/",
};

const transformFullReport = (response: any) => {
  return response.data;
};

const fetchFullReport = async (
  axiosAuth: AxiosInstance,
  filters: any,
  analysis_type: string
) => {
  let url = new URL(
    `${process.env.REACT_APP_API_BASE_URL}${endpoints[analysis_type]}`
  );
  Object.entries(filters).forEach(([filterKey, value]: any) => {
    const stringValue = value.toString();
    if (value.length > 0) {
      url.searchParams.append(filterKey, stringValue);
    }
  });

  let response: any = {
    one_mile_children: [],
    one_mile_parents: [],
    one_mile_residential: [],
    ten_mile_children: [],
    ten_mile_parents: [],
    ten_mile_residential: [],
  };

  if (analysis_type === "20_mile_analysis") {
    url.searchParams.append("miles", "20");
    response = {
      child_sites: [],
      parent_sites: [],
    };
  }

  try {
    response = await axiosAuth.get(url.href);
  } catch (e) {
    console.log("error");
  }

  return response;
};

export function useFullReport(
  session: any,
  filters: any,
  analysis_type: string
) {
  const axiosAuth = useAxiosAuth();
  return useQuery({
    queryKey: ["fullReport", filters, analysis_type],
    queryFn: async () =>
      await fetchFullReport(axiosAuth, filters, analysis_type),
    select: (data) => transformFullReport(data),
    enabled: !!session?.user,
    refetchOnWindowFocus: false,
  });
}

export function usePostReport() {
  const axiosAuth = useAxiosAuth();
  return useMutation({
    mutationFn: async (parameters: any) => {
      let url = new URL(
        `${process.env.REACT_APP_API_BASE_URL}${endpoints[parameters.analysis]}`
      );
      let payload: any = {};
      Object.entries(parameters.filters).forEach(([filterKey, value]: any) => {
        const stringValue = value.toString();
        if (value.length > 0) {
          payload[filterKey] = stringValue;
        }
      });
      if (parameters.analysis === "20_mile_analysis") {
        payload["miles"] = 20;
      }
      return await axiosAuth.post(url.href, payload, {
        responseType: "blob",
      });
    },
  });
}
