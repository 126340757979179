import React from "react";
import "./Header.css";
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import CustomButton from "../../Basics/CustomButton/CustomButton.tsx";

const Header = ({ text, onLogout, children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent" }}
      elevation={0}
    >
      <Toolbar>
        {location.pathname !== "/" && (
          <IconButton
            onClick={() => navigate("/")}
            sx={{ color: "#2E7C67", marginRight: "16px" }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          variant="h3"
          component="div"
          sx={{ flexGrow: 1, color: "#2E7C67", fontWeight: "bold" }}
        >
          {text}
        </Typography>
        <Box display="flex" alignItems="center">
          {children}
          <CustomButton
            variant="outlined"
            color="secondary"
            label="Logout"
            onClick={onLogout}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
