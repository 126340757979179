import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  useTheme,
} from "@mui/material";

import CustomButton from "../CustomButton/CustomButton.tsx";
import { tokens } from "../../../theme/theme.ts";

const OptionsButton = ({
  variant,
  color,
  options,
  onSelect,
  disabled,
  sx,
  label,
}: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [selectedIndex, setSelectedIndex] = React.useState<any>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    option: any
  ) => {
    setSelectedIndex(index);
    onSelect(option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <CustomButton
        disabled={disabled}
        aria-describedby={id}
        sx={{
          ...sx,
        }}
        variant={variant}
        color={color}
        label={label}
        onClick={handleOpen}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          maxHeight: "300px",
          minWidth: anchorEl ? anchorEl.getBoundingClientRect().width : "auto",
        }}
      >
        <List sx={{ backgroundColor: colors.surface[1] }}>
          {options.map((options: any, index: number) => (
            <ListItemButton
              key={index}
              dense
              selected={selectedIndex === index}
              onClick={(event) => {
                handleListItemClick(event, index, options);
              }}
              sx={{
                height: "30px",
                "&:hover": {
                  backgroundColor: colors.principal[1],
                  color: "#FFFFFF",
                },
                "&:active": {
                  backgroundColor: colors.secondary[2],
                  color: "#FFFFFF",
                },
              }}
            >
              <ListItemText primary={options.label} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default OptionsButton;
