import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDeleteSite } from "../../utils/api/deleteSite.ts";
import { useNotification } from "../../context/notification.context.tsx";
import CustomButton from "../Basics/CustomButton/CustomButton.tsx";

interface ConfirmDeleteModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  open,
  onClose,
  id,
}) => {
  const { mutate: deleteSite, isLoading } = useDeleteSite();
  const { addNotification } = useNotification();

  const handleDelete = () => {
    deleteSite(id, {
      onSuccess: () => {
        addNotification("The site was deleted successfully", true);
        onClose();
      },
      onError: (error) => {
        console.error("Failed to delete site", error);
        addNotification("Failed to delete the site", false);
      },
    });
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="body1"
          component="p"
          style={{ textAlign: "center", marginTop: "16px" }}
        >
          Are you sure you want to remove this site?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <CustomButton
            disabled={isLoading}
            sx={{
              paddingY: 0.5,
            }}
            variant="outlined"
            color="secondary"
            label="Cancel"
            onClick={onClose}
          />
          <CustomButton
            disabled={isLoading}
            sx={{
              paddingY: 0.5,
            }}
            variant="contained"
            color="primary"
            label="Remove"
            onClick={handleDelete}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
