import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAddNewPortfolio } from "../../utils/api/addNewPortfolio.ts";
import { useNotification } from "../../context/notification.context.tsx";
import CustomButton from "../Basics/CustomButton/CustomButton.tsx";

interface AddNewPortfolioModalProps {
  open: boolean;
  onClose: () => void;
}

const AddNewPortfolioModal: React.FC<AddNewPortfolioModalProps> = ({
  open,
  onClose,
}) => {
  const [portfolioName, setPortfolioName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string>("Luminace");
  const { mutate: addNewPortfolio, isLoading } = useAddNewPortfolio();
  const { addNotification } = useNotification();

  const handleAddPortfolio = () => {
    if (!portfolioName.trim()) {
      addNotification("Portfolio name cannot be empty", false);
      return;
    }

    const portfolioData = {
      name: portfolioName,
      groups: [
        {
          name: selectedGroup,
        },
      ],
    };

    addNewPortfolio(portfolioData, {
      onSuccess: () => {
        addNotification("Portfolio added successfully", true);
        onClose();
        resetForm();
      },
      onError: (error) => {
        addNotification("Failed to add portfolio", false);
        console.error("Failed to add new portfolio", error);
      },
    });
  };

  const handleCheckboxChange = (group: string) => {
    setSelectedGroup(group);
  };

  const resetForm = () => {
    setPortfolioName("");
    setSelectedGroup("Luminace");
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          ADD A PORTFOLIO
        </Typography>

        <TextField
          fullWidth
          label="Name"
          value={portfolioName}
          onChange={(e) => setPortfolioName(e.target.value)}
          variant="outlined"
          margin="normal"
          error={!portfolioName.trim() && portfolioName !== ""}
          helperText={
            !portfolioName.trim() && portfolioName !== ""
              ? "Name is required"
              : ""
          }
        />

        <Typography
          variant="body1"
          style={{ fontWeight: "bold", marginTop: "16px" }}
        >
          Select a permissions group
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={selectedGroup === "Luminace"}
              onChange={() => handleCheckboxChange("Luminace")}
            />
          }
          label="Luminace"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedGroup === "SSI"}
              onChange={() => handleCheckboxChange("SSI")}
            />
          }
          label="SSI"
        />

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <CustomButton
            disabled={isLoading}
            sx={{
              paddingY: 0.5,
            }}
            variant="outlined"
            color="secondary"
            label=" Cancel"
            onClick={handleClose}
          />
          <CustomButton
            disabled={!portfolioName.trim() || !selectedGroup || isLoading}
            sx={{
              paddingY: 0.5,
            }}
            variant="contained"
            color="primary"
            label="ADD PORTFOLIO"
            onClick={handleAddPortfolio}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddNewPortfolioModal;
