import React, { useState } from "react";
import { Autocomplete, Checkbox, TextField, useTheme } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ListboxComponent from "./Listbox.tsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { tokens } from "../../theme/theme.ts";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomDropdown = ({
  Title,
  Options,
  onChangeValue,
  loading = false,
  Icon = KeyboardArrowDownIcon,
  iconSize = "large",
}: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  const handleOnChange = (event: any, newValue: any) => {
    setSelectedOptions(newValue);
    onChangeValue(newValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    const pastedValues = paste
      .split(/\r?\n/)
      .map((value) => value.trim())
      .filter((value) => value !== "");

    const matchedOptions = pastedValues.filter((value) =>
      Options.includes(value)
    );

    const newSelectedOptions = Array.from(
      new Set([...selectedOptions, ...matchedOptions])
    );

    setSelectedOptions(newSelectedOptions);
    onChangeValue(newSelectedOptions);
  };

  return (
    <Autocomplete
      multiple
      loading={loading}
      id="checkboxes-tags"
      options={Options}
      limitTags={2}
      disableListWrap
      disableCloseOnSelect
      value={selectedOptions}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      popupIcon={
        <Icon
          fontWeight={"bold"}
          fontSize={iconSize}
          sx={{ color: colors.principal[1] }}
        />
      }
      getOptionLabel={(option) => option}
      onChange={handleOnChange}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              sx={{
                color: "#2E7C67",
                "&.Mui-checked": {
                  color: selected ? "#2E7C67" : "#B2160E",
                },
              }}
            />
            {option}
          </li>
        );
      }}
      sx={{
        width: 300,
        margin: 2,
        "& .MuiInput-underline:before": {
          borderBottomColor: "#2E7C67", // Default underline color
        },
        "&:hover .MuiInput-underline:before": {
          borderBottomColor: "#2E7C67", // Underline color on hover
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#2E7C67", // Underline color when focused
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={Title}
          placeholder="Select Options"
          InputProps={{
            ...params.InputProps,
            onPaste: handlePaste,
          }}
          InputLabelProps={{
            sx: {
              color: "#2E7C67", // Label color
              "&.Mui-focused": {
                color: "#2E7C67",
              },
            },
          }}
        />
      )}
    />
  );
};

export default CustomDropdown;
