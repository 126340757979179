import { Button, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../../theme/theme.ts";

const CustomButton = ({ label, sx, ...props }: any) => {
  const { variant, color } = props;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let customDefaultStyle: any = {
    paddingX: sx?.paddingX ? sx.paddingX : 2,
    paddingY: sx?.paddingY ? sx.paddingY : 0,
  };
  if (variant === "contained" && color === "primary") {
    customDefaultStyle = {
      ...customDefaultStyle,
      backgroundColor: colors.principal[1],
      "&:hover": {
        backgroundColor: colors.secondary[1],
      },
      "&:active": {
        backgroundColor: colors.gray[1],
      },
      "&:disabled": {
        backgroundColor: colors.gray[2],
        color: "#FFFFFF",
      },
    };
  }
  if (variant === "outlined" && color === "secondary") {
    customDefaultStyle = {
      ...customDefaultStyle,
      border: "1px solid",
      color: colors.principal[1],
      "&:hover": {
        backgroundColor: colors.secondary[2],
      },
      "&:active": {
        color: colors.gray[1],
        border: "1px solid " + colors.gray[1],
      },
      "&:disabled": {
        color: colors.gray[1],
        border: "1px solid " + colors.gray[1],
      },
    };
  }

  return (
    <Button {...props} sx={{ ...sx, ...customDefaultStyle }}>
      {label}
    </Button>
  );
};

export default CustomButton;
