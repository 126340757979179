import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./pages/Login/Login.tsx";
import MainPage from "./pages/MainPage/MainPage.tsx";
import { NotificationProvider } from "./context/notification.context.tsx";
import { AuthProvider, useAuth } from "./context/auth.context.tsx";
import Portfolios from "./pages/Portfolios/Portfolios.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HealthCheck from "./components/Healthcheck/HealthCheck.tsx";
import { ColorModeContext, useMode } from "./theme/theme.ts";
import { ThemeProvider } from "@mui/material";

const PrivateRoute: React.FC = () => {
  const { session } = useAuth();
  if (!session?.token) return <Navigate to="/login" />;
  return <Outlet />;
};

const App: React.FC = () => {
  const [theme, colorMode] = useMode();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      })
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/health-check" element={<HealthCheck />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/portfolios" element={<Portfolios />} />
                  </Route>
                </Routes>
              </QueryClientProvider>
            </NotificationProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
