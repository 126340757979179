import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  MenuItem,
  Paper,
} from "@mui/material";
import { useEditSite } from "../../utils/api/editSite.ts";
import { useAuth } from "../../context/auth.context.tsx";
import { useNotification } from "../../context/notification.context.tsx";
import { usePortfolios } from "../../utils/api/usePortfolios.ts";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useSite } from "../../utils/api/useSites.ts";
import { useSiteGeometry } from "../../utils/api/useSiteGeometry.ts";
// import PolygonMap from "../PolygonMap/PolygonMap.tsx";
import { useEditSiteGeometry } from "../../utils/api/editSiteGeometry.ts";
import CustomButton from "../Basics/CustomButton/CustomButton.tsx";

interface EditNewSiteModalProps {
  open: boolean;
  onClose: () => void;
  id: any;
}

const EditNewSiteModal: React.FC<EditNewSiteModalProps> = ({
  open,
  onClose,
  id,
}) => {
  // const { mutate: editSite, isLoading } = useEditSite();
  const { mutate: editSite, isLoading } = useEditSite();
  const { mutate: editSiteGeometry } = useEditSiteGeometry();
  const { session } = useAuth();
  const { addNotification } = useNotification();

  const { data: PortfoliosData, isLoading: isLoadingPortfoliosData } =
    usePortfolios(session);

  const {
    data: siteData,
    isLoading: isLoadingSiteData,
    refetch: refetchSiteData,
  } = useSite(session, id);

  const {
    data: siteGeometry,
    isLoading: isLoadingSiteGeometry,
    refetch: refetchSiteGeometry,
  } = useSiteGeometry(session, siteData?.sitegeometry);

  // Form data state
  const [formData, setFormData] = useState<any>({
    sam_id: "",
    name: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
    portfolio: "",
    docket_id: "",
    dc_kw: "",
    ac_kw: "",
    polygon: "",
    ac_source: "",
    dc_source: "",
  });

  const [initialPolygon, setInitialPolygon] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState<{
    [key: string]: string | null;
  }>({
    polygon: null,
  });

  // Completely reset the modal state when it closes
  useEffect(() => {
    if (!open) {
      setFormData({
        sam_id: "",
        name: "",
        city: "",
        state: "",
        latitude: "",
        longitude: "",
        portfolio: "",
        docket_id: "",
        dc_kw: "",
        ac_kw: "",
        polygon: "",
        ac_source: "",
        dc_source: "",
      });
      setInitialPolygon("");
      // Reset siteData and siteGeometry here
      refetchSiteData();
      refetchSiteGeometry();
    }
  }, [open, refetchSiteData, refetchSiteGeometry]);

  // Fetch fresh data when the modal opens and `id` changes
  useEffect(() => {
    if (open) {
      refetchSiteData(); // Force refetch the site data
      refetchSiteGeometry(); // Force refetch the site geometry
    }
  }, [open, id, refetchSiteData, refetchSiteGeometry]);

  // Update form data once siteData and siteGeometry are fully loaded
  useEffect(() => {
    if (
      open &&
      siteData &&
      siteGeometry &&
      !isLoadingSiteData &&
      !isLoadingSiteGeometry
    ) {
      setFormData({
        sam_id: siteData.sam_id || "",
        name: siteData.name || "",
        city: siteData.city || "",
        state: siteData.state || "",
        latitude: siteData.latitude || "",
        longitude: siteData.longitude || "",
        portfolio: siteData.portfolio || "",
        docket_id: siteData.docket_id || "",
        dc_kw: siteData.dc_kw || "",
        ac_kw: siteData.ac_kw || "",
        polygon: siteGeometry.poly || "",
        ac_source: siteData.ac_source || "",
        dc_source: siteData.dc_source || "",
      });
      setInitialPolygon(siteGeometry.poly || "");
    }
  }, [open, siteData, siteGeometry, isLoadingSiteData, isLoadingSiteGeometry]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "dc_kw" || name === "ac_kw" ? parseFloat(value) || "" : value,
    });
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const newPortfolioValue = `${process.env.REACT_APP_API_BASE_URL}portfolios/${formData.portfolio}/`;
    formData.portfolio = newPortfolioValue;

    formData.dc_source = formData.dc_source.replace(/ /g, "_");
    formData.ac_source = formData.ac_source.replace(/ /g, "_");
    formData.polygon = formData.polygon || "";

    if (formData.sam_id && formData.name && formData.dc_kw && formData.ac_kw) {
      editSite(
        { ...formData, id },
        {
          onSuccess: () => {
            // Check if polygon has changed
            const polygonChanged = formData.polygon !== initialPolygon;

            if (polygonChanged && siteGeometry && formData.polygon) {
              const siteGeometryData = {
                ...siteGeometry,
                poly: formData.polygon,
                id: siteGeometry.id,
              };

              editSiteGeometry(siteGeometryData, {
                onSuccess: () => {
                  addNotification("The site was edited successfully", true);
                  onClose();
                },
                onError: (error) => {
                  // Check for 400 error and set the form error
                  if (error?.response?.status === 400) {
                    setFormErrors({
                      ...formErrors,
                      polygon: "Invalid polygon",
                    });
                  } else {
                    addNotification("Failed to edit the site geometry", false);
                  }
                  setIsSubmitting(false);
                },
              });
            } else {
              addNotification("The site was edited successfully", true);
              onClose();
            }
          },
          onError: (error) => {
            addNotification("Failed to edit the site", false);
            setIsSubmitting(false);
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        }
      );
    } else {
      setIsSubmitting(false);
    }
  };

  const sourceOptions = [
    "Early stage projects",
    "Asset Registry",
    "COD Tracker",
    "SSI Constr Early Stage",
    "SSI Operational registry",
    "M&A operations",
    "utility fleet",
    "TransAlta operations",
    "ECHO Residential",
    "ASD Residential",
    "Deriva Energy",
    "X-ELIO",
    "BEP US",
  ];

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          outline: "none",
        }}
      >
        <Box sx={style}>
          {isLoadingSiteData ||
          isLoadingSiteGeometry ||
          isLoadingPortfoliosData ? (
            // Loading message while data is being fetched
            <Typography variant="h6" sx={{ p: 4, textAlign: "center" }}>
              Loading site data...
            </Typography>
          ) : (
            <>
              <Paper
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingX: 4,
                  paddingY: 2,
                }}
              >
                {formData.sam_id ? (
                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: 1,
                      fontWeight: "bold",
                    }}
                  >
                    {formData.sam_id}
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{
                      marginTop: 1,
                      fontWeight: "bold",
                    }}
                  >
                    EDIT SITE
                  </Typography>
                )}

                <IconButton onClick={onClose}>
                  <HighlightOffOutlinedIcon />
                </IconButton>
              </Paper>

              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  p: 4,
                }}
              >
                <TextField
                  label="Site Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                <TextField
                  label="State"
                  variant="outlined"
                  fullWidth
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
                <TextField
                  label="Latitude"
                  variant="outlined"
                  fullWidth
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                />
                <TextField
                  label="Longitude"
                  variant="outlined"
                  fullWidth
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                />
                <TextField
                  select
                  label="Portfolio"
                  variant="outlined"
                  fullWidth
                  name="portfolio"
                  value={formData.portfolio}
                  onChange={handleChange}
                >
                  {PortfoliosData?.map(
                    (portfolio: { id: number; name: string }) => (
                      <MenuItem key={portfolio.id} value={portfolio.id}>
                        {portfolio.name}
                      </MenuItem>
                    )
                  )}
                </TextField>
                <TextField
                  label="QF Docket ID"
                  variant="outlined"
                  fullWidth
                  name="docket_id"
                  value={formData.docket_id}
                  onChange={handleChange}
                />
                <TextField
                  label="Polygon"
                  variant="outlined"
                  fullWidth
                  name="polygon"
                  value={formData.polygon}
                  onChange={(e) => {
                    handleChange(e);
                    setFormErrors({ ...formErrors, polygon: null }); // Clear error when the input changes
                  }}
                  error={!!formErrors.polygon}
                  helperText={formErrors.polygon}
                />
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="DC kW"
                    variant="outlined"
                    fullWidth
                    name="dc_kw"
                    value={formData.dc_kw}
                    onChange={handleChange}
                  />
                  <TextField
                    label="AC kW"
                    variant="outlined"
                    fullWidth
                    name="ac_kw"
                    value={formData.ac_kw}
                    onChange={handleChange}
                  />
                </Box>
                {/* 
                TODO: Review the operation to be performed for this freature
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: 1,
                      fontWeight: "bold",
                    }}
                  >
                    Polygon
                  </Typography>
                  <PolygonMap
                    wktData={siteGeometry.poly}
                    Name={`${formData.name}`}
                  />
                </Box> */}

                <TextField
                  select
                  label="AC Source"
                  variant="outlined"
                  fullWidth
                  name="ac_source"
                  value={formData.ac_source}
                  onChange={handleChange}
                >
                  {sourceOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="DC Source"
                  variant="outlined"
                  fullWidth
                  name="dc_source"
                  value={formData.dc_source}
                  onChange={handleChange}
                >
                  {sourceOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <CustomButton
                    sx={{
                      paddingY: 0.5,
                    }}
                    variant="outlined"
                    color="secondary"
                    label=" Cancel"
                    onClick={onClose}
                  />

                  <CustomButton
                    disabled={isLoading}
                    sx={{
                      paddingY: 0.5,
                    }}
                    variant="contained"
                    color="primary"
                    label="Save Site"
                    onClick={handleSubmit}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EditNewSiteModal;
